:root {
  --highlight-bg-color: rgba(180, 0, 170, 1);
  --highlight-selected-bg-color: rgba(0, 100, 0, 1);
}

@media screen and (forced-colors: active) {
  :root {
    --highlight-bg-color: Highlight;
    --highlight-selected-bg-color: ButtonText;
  }
}

.textLayer {
  position: absolute;
  text-align: initial;
  left: 0;
  top: 0;
  overflow: hidden;
  opacity: 0.25;
  line-height: 0;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  text-size-adjust: none;
  forced-color-adjust: none;
  transform-origin: 0 0;
  z-index: 2;
}

.textLayer :is(span, br) {
  color: transparent;
  position: absolute;
  white-space: pre;
  cursor: text;
  transform-origin: 0% 0%;
}

/* Only necessary in Google Chrome, see issue 14205, and most unfortunately
 * the problem doesn't show up in "text" reference tests. */
.textLayer span.markedContent {
  top: 0;
  height: 0;
}

.textLayer .highlight {
  margin: -1px;
  padding: 1px;
  background-color: var(--highlight-bg-color);
  border-radius: 4px;
}

.textLayer .highlight.appended {
  position: initial;
}

.textLayer .highlight.begin {
  border-radius: 4px 0 0 4px;
}

.textLayer .highlight.end {
  border-radius: 0 4px 4px 0;
}

.textLayer .highlight.middle {
  border-radius: 0;
}

.textLayer .highlight.selected {
  background-color: var(--highlight-selected-bg-color);
}

.textLayer ::-moz-selection {
  background: blue;
  background: AccentColor; /* stylelint-disable-line declaration-block-no-duplicate-properties */
}

.textLayer ::selection {
  background: blue;
  background: AccentColor; /* stylelint-disable-line declaration-block-no-duplicate-properties */
}

/* Avoids https://github.com/mozilla/pdf.js/issues/13840 in Chrome */
.textLayer br::-moz-selection {
  background: transparent;
}

.textLayer br::selection {
  background: transparent;
}

.textLayer .endOfContent {
  display: block;
  position: absolute;
  inset: 100% 0 0;
  z-index: -1;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.textLayer .endOfContent.active {
  top: 0;
}

:root {
  --annotation-unfocused-field-background: url("data:image/svg+xml;charset=UTF-8,<svg width='1px' height='1px' xmlns='http://www.w3.org/2000/svg'><rect width='100%' height='100%' style='fill:rgba(0, 54, 255, 0.13);'/></svg>");
  --input-focus-border-color: Highlight;
  --input-focus-outline: 1px solid Canvas;
  --input-unfocused-border-color: transparent;
  --input-disabled-border-color: transparent;
  --input-hover-border-color: black;
  --link-outline: none;
}

@media screen and (forced-colors: active) {
  :root {
    --input-focus-border-color: CanvasText;
    --input-unfocused-border-color: ActiveText;
    --input-disabled-border-color: GrayText;
    --input-hover-border-color: Highlight;
    --link-outline: 1.5px solid LinkText;
    --hcm-highligh-filter: invert(100%);
  }
  .annotationLayer .textWidgetAnnotation :is(input, textarea):required,
  .annotationLayer .choiceWidgetAnnotation select:required,
  .annotationLayer
    .buttonWidgetAnnotation:is(.checkBox, .radioButton)
    input:required {
    outline: 1.5px solid selectedItem;
  }

  .annotationLayer .linkAnnotation:hover {
    -webkit-backdrop-filter: var(--hcm-highligh-filter);
    backdrop-filter: var(--hcm-highligh-filter);
  }

  .annotationLayer .linkAnnotation > a:hover {
    opacity: 0 !important;
    background: none !important;
    box-shadow: none;
  }

  .annotationLayer .popupAnnotation .popup {
    outline: calc(1.5px * var(--scale-factor)) solid CanvasText !important;
    background-color: ButtonFace !important;
    color: ButtonText !important;
  }

  .annotationLayer .highlightArea:hover::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-backdrop-filter: var(--hcm-highligh-filter);
    backdrop-filter: var(--hcm-highligh-filter);
    content: "";
    pointer-events: none;
  }

  .annotationLayer .popupAnnotation.focused .popup {
    outline: calc(3px * var(--scale-factor)) solid Highlight !important;
  }
}

.annotationLayer {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  transform-origin: 0 0;
  z-index: 3;
}

.annotationLayer[data-main-rotation="90"] .norotate {
  transform: rotate(270deg) translateX(-100%);
}

.annotationLayer[data-main-rotation="180"] .norotate {
  transform: rotate(180deg) translate(-100%, -100%);
}

.annotationLayer[data-main-rotation="270"] .norotate {
  transform: rotate(90deg) translateY(-100%);
}

.annotationLayer canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.annotationLayer section {
  position: absolute;
  text-align: initial;
  pointer-events: auto;
  box-sizing: border-box;
  transform-origin: 0 0;
}

.annotationLayer .linkAnnotation {
  outline: var(--link-outline);
}

.annotationLayer :is(.linkAnnotation, .buttonWidgetAnnotation.pushButton) > a {
  position: absolute;
  font-size: 1em;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.annotationLayer
  :is(.linkAnnotation, .buttonWidgetAnnotation.pushButton):not(.hasBorder)
  > a:hover {
  opacity: 0.2;
  background-color: rgba(255, 255, 0, 1);
  box-shadow: 0 2px 10px rgba(255, 255, 0, 1);
}

.annotationLayer .linkAnnotation.hasBorder:hover {
  background-color: rgba(255, 255, 0, 0.2);
}

.annotationLayer .hasBorder {
  background-size: 100% 100%;
}

.annotationLayer .textAnnotation img {
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.annotationLayer .textWidgetAnnotation :is(input, textarea),
.annotationLayer .choiceWidgetAnnotation select,
.annotationLayer .buttonWidgetAnnotation:is(.checkBox, .radioButton) input {
  background-image: var(--annotation-unfocused-field-background);
  border: 2px solid var(--input-unfocused-border-color);
  box-sizing: border-box;
  font: calc(9px * var(--scale-factor)) sans-serif;
  height: 100%;
  margin: 0;
  vertical-align: top;
  width: 100%;
}

.annotationLayer .textWidgetAnnotation :is(input, textarea):required,
.annotationLayer .choiceWidgetAnnotation select:required,
.annotationLayer
  .buttonWidgetAnnotation:is(.checkBox, .radioButton)
  input:required {
  outline: 1.5px solid red;
}

.annotationLayer .choiceWidgetAnnotation select option {
  padding: 0;
}

.annotationLayer .buttonWidgetAnnotation.radioButton input {
  border-radius: 50%;
}

.annotationLayer .textWidgetAnnotation textarea {
  resize: none;
}

.annotationLayer .textWidgetAnnotation :is(input, textarea)[disabled],
.annotationLayer .choiceWidgetAnnotation select[disabled],
.annotationLayer
  .buttonWidgetAnnotation:is(.checkBox, .radioButton)
  input[disabled] {
  background: none;
  border: 2px solid var(--input-disabled-border-color);
  cursor: not-allowed;
}

.annotationLayer .textWidgetAnnotation :is(input, textarea):hover,
.annotationLayer .choiceWidgetAnnotation select:hover,
.annotationLayer
  .buttonWidgetAnnotation:is(.checkBox, .radioButton)
  input:hover {
  border: 2px solid var(--input-hover-border-color);
}

.annotationLayer .textWidgetAnnotation :is(input, textarea):hover,
.annotationLayer .choiceWidgetAnnotation select:hover,
.annotationLayer .buttonWidgetAnnotation.checkBox input:hover {
  border-radius: 2px;
}

.annotationLayer .textWidgetAnnotation :is(input, textarea):focus,
.annotationLayer .choiceWidgetAnnotation select:focus {
  background: none;
  border: 2px solid var(--input-focus-border-color);
  border-radius: 2px;
  outline: var(--input-focus-outline);
}

.annotationLayer .buttonWidgetAnnotation:is(.checkBox, .radioButton) :focus {
  background-image: none;
  background-color: transparent;
}

.annotationLayer .buttonWidgetAnnotation.checkBox :focus {
  border: 2px solid var(--input-focus-border-color);
  border-radius: 2px;
  outline: var(--input-focus-outline);
}

.annotationLayer .buttonWidgetAnnotation.radioButton :focus {
  border: 2px solid var(--input-focus-border-color);
  outline: var(--input-focus-outline);
}

.annotationLayer .buttonWidgetAnnotation.checkBox input:checked::before,
.annotationLayer .buttonWidgetAnnotation.checkBox input:checked::after,
.annotationLayer .buttonWidgetAnnotation.radioButton input:checked::before {
  background-color: CanvasText;
  content: "";
  display: block;
  position: absolute;
}

.annotationLayer .buttonWidgetAnnotation.checkBox input:checked::before,
.annotationLayer .buttonWidgetAnnotation.checkBox input:checked::after {
  height: 80%;
  left: 45%;
  width: 1px;
}

.annotationLayer .buttonWidgetAnnotation.checkBox input:checked::before {
  transform: rotate(45deg);
}

.annotationLayer .buttonWidgetAnnotation.checkBox input:checked::after {
  transform: rotate(-45deg);
}

.annotationLayer .buttonWidgetAnnotation.radioButton input:checked::before {
  border-radius: 50%;
  height: 50%;
  left: 30%;
  top: 20%;
  width: 50%;
}

.annotationLayer .textWidgetAnnotation input.comb {
  font-family: monospace;
  padding-left: 2px;
  padding-right: 0;
}

.annotationLayer .textWidgetAnnotation input.comb:focus {
  /*
   * Letter spacing is placed on the right side of each character. Hence, the
   * letter spacing of the last character may be placed outside the visible
   * area, causing horizontal scrolling. We avoid this by extending the width
   * when the element has focus and revert this when it loses focus.
   */
  width: 103%;
}

.annotationLayer .buttonWidgetAnnotation:is(.checkBox, .radioButton) input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.annotationLayer .fileAttachmentAnnotation .popupTriggerArea {
  height: 100%;
  width: 100%;
}

.annotationLayer .popupAnnotation {
  position: absolute;
  font-size: calc(9px * var(--scale-factor));
  pointer-events: none;
  width: -moz-max-content;
  width: max-content;
  max-width: 45%;
  height: auto;
}

.annotationLayer .popup {
  background-color: rgba(255, 255, 153, 1);
  box-shadow: 0 calc(2px * var(--scale-factor)) calc(5px * var(--scale-factor))
    rgba(136, 136, 136, 1);
  border-radius: calc(2px * var(--scale-factor));
  outline: 1.5px solid rgb(255, 255, 74);
  padding: calc(6px * var(--scale-factor));
  cursor: pointer;
  font: message-box;
  white-space: normal;
  word-wrap: break-word;
  pointer-events: auto;
}

.annotationLayer .popupAnnotation.focused .popup {
  outline-width: 3px;
}

.annotationLayer .popup * {
  font-size: calc(9px * var(--scale-factor));
}

.annotationLayer .popup > .header {
  display: inline-block;
}

.annotationLayer .popup > .header h1 {
  display: inline;
}

.annotationLayer .popup > .header .popupDate {
  display: inline-block;
  margin-left: calc(5px * var(--scale-factor));
  width: -moz-fit-content;
  width: fit-content;
}

.annotationLayer .popupContent {
  border-top: 1px solid rgba(51, 51, 51, 1);
  margin-top: calc(2px * var(--scale-factor));
  padding-top: calc(2px * var(--scale-factor));
}

.annotationLayer .richText > * {
  white-space: pre-wrap;
  font-size: calc(9px * var(--scale-factor));
}

.annotationLayer .popupTriggerArea {
  cursor: pointer;
}

.annotationLayer section svg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.annotationLayer .annotationTextContent {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  pointer-events: none;
}

.annotationLayer .annotationTextContent span {
  width: 100%;
  display: inline-block;
}

.annotationLayer svg.quadrilateralsContainer {
  contain: strict;
  width: 0;
  height: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

:root {
  --xfa-unfocused-field-background: url("data:image/svg+xml;charset=UTF-8,<svg width='1px' height='1px' xmlns='http://www.w3.org/2000/svg'><rect width='100%' height='100%' style='fill:rgba(0, 54, 255, 0.13);'/></svg>");
  --xfa-focus-outline: auto;
}

@media screen and (forced-colors: active) {
  :root {
    --xfa-focus-outline: 2px solid CanvasText;
  }
  .xfaLayer *:required {
    outline: 1.5px solid selectedItem;
  }
}

.xfaLayer {
  background-color: transparent;
}

.xfaLayer .highlight {
  margin: -1px;
  padding: 1px;
  background-color: rgba(239, 203, 237, 1);
  border-radius: 4px;
}

.xfaLayer .highlight.appended {
  position: initial;
}

.xfaLayer .highlight.begin {
  border-radius: 4px 0 0 4px;
}

.xfaLayer .highlight.end {
  border-radius: 0 4px 4px 0;
}

.xfaLayer .highlight.middle {
  border-radius: 0;
}

.xfaLayer .highlight.selected {
  background-color: rgba(203, 223, 203, 1);
}

.xfaPage {
  overflow: hidden;
  position: relative;
}

.xfaContentarea {
  position: absolute;
}

.xfaPrintOnly {
  display: none;
}

.xfaLayer {
  position: absolute;
  text-align: initial;
  top: 0;
  left: 0;
  transform-origin: 0 0;
  line-height: 1.2;
}

.xfaLayer * {
  color: inherit;
  font: inherit;
  font-style: inherit;
  font-weight: inherit;
  font-kerning: inherit;
  letter-spacing: -0.01px;
  text-align: inherit;
  text-decoration: inherit;
  box-sizing: border-box;
  background-color: transparent;
  padding: 0;
  margin: 0;
  pointer-events: auto;
  line-height: inherit;
}

.xfaLayer *:required {
  outline: 1.5px solid red;
}

.xfaLayer div,
.xfaLayer svg,
.xfaLayer svg * {
  pointer-events: none;
}

.xfaLayer a {
  color: blue;
}

.xfaRich li {
  margin-left: 3em;
}

.xfaFont {
  color: black;
  font-weight: normal;
  font-kerning: none;
  font-size: 10px;
  font-style: normal;
  letter-spacing: 0;
  text-decoration: none;
  vertical-align: 0;
}

.xfaCaption {
  overflow: hidden;
  flex: 0 0 auto;
}

.xfaCaptionForCheckButton {
  overflow: hidden;
  flex: 1 1 auto;
}

.xfaLabel {
  height: 100%;
  width: 100%;
}

.xfaLeft {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.xfaRight {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

:is(.xfaLeft, .xfaRight) > :is(.xfaCaption, .xfaCaptionForCheckButton) {
  max-height: 100%;
}

.xfaTop {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.xfaBottom {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
}

:is(.xfaTop, .xfaBottom) > :is(.xfaCaption, .xfaCaptionForCheckButton) {
  width: 100%;
}

.xfaBorder {
  background-color: transparent;
  position: absolute;
  pointer-events: none;
}

.xfaWrapped {
  width: 100%;
  height: 100%;
}

:is(.xfaTextfield, .xfaSelect):focus {
  background-image: none;
  background-color: transparent;
  outline: var(--xfa-focus-outline);
  outline-offset: -1px;
}

:is(.xfaCheckbox, .xfaRadio):focus {
  outline: var(--xfa-focus-outline);
}

.xfaTextfield,
.xfaSelect {
  height: 100%;
  width: 100%;
  flex: 1 1 auto;
  border: none;
  resize: none;
  background-image: var(--xfa-unfocused-field-background);
}

.xfaSelect {
  padding-inline: 2px;
}

:is(.xfaTop, .xfaBottom) > :is(.xfaTextfield, .xfaSelect) {
  flex: 0 1 auto;
}

.xfaButton {
  cursor: pointer;
  width: 100%;
  height: 100%;
  border: none;
  text-align: center;
}

.xfaLink {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.xfaCheckbox,
.xfaRadio {
  width: 100%;
  height: 100%;
  flex: 0 0 auto;
  border: none;
}

.xfaRich {
  white-space: pre-wrap;
  width: 100%;
  height: 100%;
}

.xfaImage {
  -o-object-position: left top;
  object-position: left top;
  -o-object-fit: contain;
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.xfaLrTb,
.xfaRlTb,
.xfaTb {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.xfaLr {
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.xfaRl {
  display: flex;
  flex-direction: row-reverse;
  align-items: stretch;
}

.xfaTb > div {
  justify-content: left;
}

.xfaPosition {
  position: relative;
}

.xfaArea {
  position: relative;
}

.xfaValignMiddle {
  display: flex;
  align-items: center;
}

.xfaTable {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.xfaTable .xfaRow {
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.xfaTable .xfaRlRow {
  display: flex;
  flex-direction: row-reverse;
  align-items: stretch;
  flex: 1;
}

.xfaTable .xfaRlRow > div {
  flex: 1;
}

:is(.xfaNonInteractive, .xfaDisabled, .xfaReadOnly) :is(input, textarea) {
  background: initial;
}

@media print {
  .xfaTextfield,
  .xfaSelect {
    background: transparent;
  }

  .xfaSelect {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    text-indent: 1px;
    text-overflow: "";
  }
}

/* Ignored in GECKOVIEW builds: */

:root {
  --outline-width: 2px;
  --outline-color: #0060df;
  --outline-around-width: 1px;
  --outline-around-color: #f0f0f4;
  --hover-outline-around-color: var(--outline-around-color);
  --focus-outline: solid var(--outline-width) var(--outline-color);
  --unfocus-outline: solid var(--outline-width) transparent;
  --focus-outline-around: solid var(--outline-around-width)
    var(--outline-around-color);
  --hover-outline-color: #8f8f9d;
  --hover-outline: solid var(--outline-width) var(--hover-outline-color);
  --hover-outline-around: solid var(--outline-around-width)
    var(--hover-outline-around-color);
  --freetext-line-height: 1.35;
  --freetext-padding: 2px;
  --resizer-bg-color: var(--outline-color);
  --resizer-size: 6px;
  --resizer-shift: calc(
    0px - (var(--outline-width) + var(--resizer-size)) / 2 -
      var(--outline-around-width)
  );
  --editorFreeText-editing-cursor: text;
  --editorInk-editing-cursor: pointer;

  --alt-text-opacity: 0.8;
  //--alt-text-add-image: url(images/altText_add.svg);
  //--alt-text-done-image: url(images/altText_done.svg);
  --alt-text-bg-color: rgba(43, 42, 51, var(--alt-text-opacity));
  --alt-text-fg-color: #fbfbfe;
  --alt-text-border-color: var(--alt-text-bg-color);
  --alt-text-hover-bg-color: rgba(82, 82, 94, var(--alt-text-opacity));
  --alt-text-hover-fg-color: var(--alt-text-fg-color);
  --alt-text-hover-border-color: var(--alt-text-hover-bg-color);
  --alt-text-active-bg-color: rgba(91, 91, 102, var(--alt-text-opacity));
  --alt-text-active-fg-color: var(--alt-text-fg-color);
  --alt-text-active-border-color: var(--alt-text-hover-bg-color);
  --alt-text-focus-outline-color: #0060df;
  --alt-text-focus-border-color: #f0f0f4;
  --alt-text-shadow: 0 2px 6px 0 rgba(28, 27, 34, 0.5);
}

@media (-webkit-min-device-pixel-ratio: 1.1), (min-resolution: 1.1dppx) {
  :root {
  }
}

@media screen and (forced-colors: active) {
  :root {
    --outline-color: CanvasText;
    --outline-around-color: ButtonFace;
    --resizer-bg-color: ButtonText;
    --hover-outline-color: Highlight;
    --hover-outline-around-color: SelectedItemText;

    --alt-text-bg-color: Canvas;
    --alt-text-fg-color: ButtonText;
    --alt-text-border-color: ButtonText;
    --alt-text-hover-bg-color: Canvas;
    --alt-text-hover-fg-color: SelectedItem;
    --alt-text-hover-border-color: SelectedItem;
    --alt-text-active-bg-color: ButtonFace;
    --alt-text-active-fg-color: SelectedItem;
    --alt-text-active-border-color: ButtonText;
    --alt-text-focus-outline-color: CanvasText;
    --alt-text-focus-border-color: ButtonText;
    --alt-text-shadow: none;
    --alt-text-opacity: 1;
  }
}

[data-editor-rotation="90"] {
  transform: rotate(90deg);
}

[data-editor-rotation="180"] {
  transform: rotate(180deg);
}

[data-editor-rotation="270"] {
  transform: rotate(270deg);
}

.annotationEditorLayer {
  background: transparent;
  position: absolute;
  inset: 0;
  font-size: calc(100px * var(--scale-factor));
  transform-origin: 0 0;
  cursor: auto;
  z-index: 4;
}

.annotationEditorLayer.waiting {
  content: "";
  cursor: wait;
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
}

.annotationEditorLayer.freeTextEditing {
  cursor: var(--editorFreeText-editing-cursor);
}

.annotationEditorLayer.inkEditing {
  cursor: var(--editorInk-editing-cursor);
}

.annotationEditorLayer :is(.freeTextEditor, .inkEditor, .stampEditor) {
  position: absolute;
  background: transparent;
  z-index: 1;
  transform-origin: 0 0;
  cursor: auto;
  max-width: 100%;
  max-height: 100%;
  border: var(--unfocus-outline);
}

.annotationEditorLayer
  .draggable.selectedEditor:is(.freeTextEditor, .inkEditor, .stampEditor) {
  cursor: move;
}

.annotationEditorLayer
  .selectedEditor:is(.freeTextEditor, .inkEditor, .stampEditor) {
  border: var(--focus-outline);
  outline: var(--focus-outline-around);
}

.annotationEditorLayer
  .selectedEditor:is(.freeTextEditor, .inkEditor, .stampEditor)::before {
  /*
    This is a workaround for the lack of support for stripes(...) (see
    https://drafts.csswg.org/css-images-4/#stripes).
    The outline should be composed of 1px white, 2px blue and 1px white.
    This could be achieved in different ways:
      - using a linear-gradient;
      - using a box-shadow;
      - using an outline on the selected element and an outline+border on
        the ::before pseudo-element.
    All these options lead to incorrect rendering likely due to rounding
    issues.
    That said it doesn't mean that the current is ideal, but it's the best
    we could come up with for the moment.
    One drawback of this approach is that we use a border on the selected
    element which means that we must take care of it when positioning the
    div in js (see AnnotationEditor._borderLineWidth in editor.js).
  */
  content: "";
  position: absolute;
  inset: 0;
  border: var(--focus-outline-around);
  pointer-events: none;
}

.annotationEditorLayer
  :is(.freeTextEditor, .inkEditor, .stampEditor):hover:not(.selectedEditor) {
  border: var(--hover-outline);
  outline: var(--hover-outline-around);
}

.annotationEditorLayer
  :is(.freeTextEditor, .inkEditor, .stampEditor):hover:not(.selectedEditor)::before {
  content: "";
  position: absolute;
  inset: 0;
  border: var(--focus-outline-around);
}

.annotationEditorLayer .freeTextEditor {
  padding: calc(var(--freetext-padding) * var(--scale-factor));
  width: auto;
  height: auto;
  touch-action: none;
}

.annotationEditorLayer .freeTextEditor .internal {
  background: transparent;
  border: none;
  inset: 0;
  overflow: visible;
  white-space: nowrap;
  font: 10px sans-serif;
  line-height: var(--freetext-line-height);
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.annotationEditorLayer .freeTextEditor .overlay {
  position: absolute;
  display: none;
  background: transparent;
  inset: 0;
  width: 100%;
  height: 100%;
}

.annotationEditorLayer .freeTextEditor .overlay.enabled {
  display: block;
}

.annotationEditorLayer .freeTextEditor .internal:empty::before {
  content: attr(default-content);
  color: gray;
}

.annotationEditorLayer .freeTextEditor .internal:focus {
  outline: none;
  -webkit-user-select: auto;
  -moz-user-select: auto;
  user-select: auto;
}

.annotationEditorLayer .inkEditor {
  width: 100%;
  height: 100%;
}

.annotationEditorLayer .inkEditor.editing {
  cursor: inherit;
}

.annotationEditorLayer .inkEditor .inkEditorCanvas {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  touch-action: none;
}

.annotationEditorLayer .stampEditor {
  width: auto;
  height: auto;
}

.annotationEditorLayer .stampEditor canvas {
  width: 100%;
  height: 100%;
}

.annotationEditorLayer
  :is(.freeTextEditor, .inkEditor, .stampEditor)
  > .resizers {
  position: absolute;
  inset: 0;
}

.annotationEditorLayer
  :is(.freeTextEditor, .inkEditor, .stampEditor)
  > .resizers.hidden {
  display: none;
}

.annotationEditorLayer
  :is(.freeTextEditor, .inkEditor, .stampEditor)
  > .resizers
  > .resizer {
  width: var(--resizer-size);
  height: var(--resizer-size);
  background: content-box var(--resizer-bg-color);
  border: var(--focus-outline-around);
  border-radius: 2px;
  position: absolute;
}

.annotationEditorLayer
  :is(.freeTextEditor, .inkEditor, .stampEditor)
  > .resizers
  > .resizer.topLeft {
  top: var(--resizer-shift);
  left: var(--resizer-shift);
}

.annotationEditorLayer
  :is(.freeTextEditor, .inkEditor, .stampEditor)
  > .resizers
  > .resizer.topMiddle {
  top: var(--resizer-shift);
  left: calc(50% + var(--resizer-shift));
}

.annotationEditorLayer
  :is(.freeTextEditor, .inkEditor, .stampEditor)
  > .resizers
  > .resizer.topRight {
  top: var(--resizer-shift);
  right: var(--resizer-shift);
}

.annotationEditorLayer
  :is(.freeTextEditor, .inkEditor, .stampEditor)
  > .resizers
  > .resizer.middleRight {
  top: calc(50% + var(--resizer-shift));
  right: var(--resizer-shift);
}

.annotationEditorLayer
  :is(.freeTextEditor, .inkEditor, .stampEditor)
  > .resizers
  > .resizer.bottomRight {
  bottom: var(--resizer-shift);
  right: var(--resizer-shift);
}

.annotationEditorLayer
  :is(.freeTextEditor, .inkEditor, .stampEditor)
  > .resizers
  > .resizer.bottomMiddle {
  bottom: var(--resizer-shift);
  left: calc(50% + var(--resizer-shift));
}

.annotationEditorLayer
  :is(.freeTextEditor, .inkEditor, .stampEditor)
  > .resizers
  > .resizer.bottomLeft {
  bottom: var(--resizer-shift);
  left: var(--resizer-shift);
}

.annotationEditorLayer
  :is(.freeTextEditor, .inkEditor, .stampEditor)
  > .resizers
  > .resizer.middleLeft {
  top: calc(50% + var(--resizer-shift));
  left: var(--resizer-shift);
}

.annotationEditorLayer[data-main-rotation="0"]
  :is([data-editor-rotation="0"], [data-editor-rotation="180"])
  > .resizers
  > .resizer.topLeft,
.annotationEditorLayer[data-main-rotation="90"]
  :is([data-editor-rotation="270"], [data-editor-rotation="90"])
  > .resizers
  > .resizer.topLeft,
.annotationEditorLayer[data-main-rotation="180"]
  :is([data-editor-rotation="180"], [data-editor-rotation="0"])
  > .resizers
  > .resizer.topLeft,
.annotationEditorLayer[data-main-rotation="270"]
  :is([data-editor-rotation="90"], [data-editor-rotation="270"])
  > .resizers
  > .resizer.topLeft,
.annotationEditorLayer[data-main-rotation="0"]
  :is([data-editor-rotation="0"], [data-editor-rotation="180"])
  > .resizers
  > .resizer.bottomRight,
.annotationEditorLayer[data-main-rotation="90"]
  :is([data-editor-rotation="270"], [data-editor-rotation="90"])
  > .resizers
  > .resizer.bottomRight,
.annotationEditorLayer[data-main-rotation="180"]
  :is([data-editor-rotation="180"], [data-editor-rotation="0"])
  > .resizers
  > .resizer.bottomRight,
.annotationEditorLayer[data-main-rotation="270"]
  :is([data-editor-rotation="90"], [data-editor-rotation="270"])
  > .resizers
  > .resizer.bottomRight {
  cursor: nwse-resize;
}

.annotationEditorLayer[data-main-rotation="0"]
  :is([data-editor-rotation="0"], [data-editor-rotation="180"])
  > .resizers
  > .resizer.topMiddle,
.annotationEditorLayer[data-main-rotation="90"]
  :is([data-editor-rotation="270"], [data-editor-rotation="90"])
  > .resizers
  > .resizer.topMiddle,
.annotationEditorLayer[data-main-rotation="180"]
  :is([data-editor-rotation="180"], [data-editor-rotation="0"])
  > .resizers
  > .resizer.topMiddle,
.annotationEditorLayer[data-main-rotation="270"]
  :is([data-editor-rotation="90"], [data-editor-rotation="270"])
  > .resizers
  > .resizer.topMiddle,
.annotationEditorLayer[data-main-rotation="0"]
  :is([data-editor-rotation="0"], [data-editor-rotation="180"])
  > .resizers
  > .resizer.bottomMiddle,
.annotationEditorLayer[data-main-rotation="90"]
  :is([data-editor-rotation="270"], [data-editor-rotation="90"])
  > .resizers
  > .resizer.bottomMiddle,
.annotationEditorLayer[data-main-rotation="180"]
  :is([data-editor-rotation="180"], [data-editor-rotation="0"])
  > .resizers
  > .resizer.bottomMiddle,
.annotationEditorLayer[data-main-rotation="270"]
  :is([data-editor-rotation="90"], [data-editor-rotation="270"])
  > .resizers
  > .resizer.bottomMiddle {
  cursor: ns-resize;
}

.annotationEditorLayer[data-main-rotation="0"]
  :is([data-editor-rotation="0"], [data-editor-rotation="180"])
  > .resizers
  > .resizer.topRight,
.annotationEditorLayer[data-main-rotation="90"]
  :is([data-editor-rotation="270"], [data-editor-rotation="90"])
  > .resizers
  > .resizer.topRight,
.annotationEditorLayer[data-main-rotation="180"]
  :is([data-editor-rotation="180"], [data-editor-rotation="0"])
  > .resizers
  > .resizer.topRight,
.annotationEditorLayer[data-main-rotation="270"]
  :is([data-editor-rotation="90"], [data-editor-rotation="270"])
  > .resizers
  > .resizer.topRight,
.annotationEditorLayer[data-main-rotation="0"]
  :is([data-editor-rotation="0"], [data-editor-rotation="180"])
  > .resizers
  > .resizer.bottomLeft,
.annotationEditorLayer[data-main-rotation="90"]
  :is([data-editor-rotation="270"], [data-editor-rotation="90"])
  > .resizers
  > .resizer.bottomLeft,
.annotationEditorLayer[data-main-rotation="180"]
  :is([data-editor-rotation="180"], [data-editor-rotation="0"])
  > .resizers
  > .resizer.bottomLeft,
.annotationEditorLayer[data-main-rotation="270"]
  :is([data-editor-rotation="90"], [data-editor-rotation="270"])
  > .resizers
  > .resizer.bottomLeft {
  cursor: nesw-resize;
}

.annotationEditorLayer[data-main-rotation="0"]
  :is([data-editor-rotation="0"], [data-editor-rotation="180"])
  > .resizers
  > .resizer.middleRight,
.annotationEditorLayer[data-main-rotation="90"]
  :is([data-editor-rotation="270"], [data-editor-rotation="90"])
  > .resizers
  > .resizer.middleRight,
.annotationEditorLayer[data-main-rotation="180"]
  :is([data-editor-rotation="180"], [data-editor-rotation="0"])
  > .resizers
  > .resizer.middleRight,
.annotationEditorLayer[data-main-rotation="270"]
  :is([data-editor-rotation="90"], [data-editor-rotation="270"])
  > .resizers
  > .resizer.middleRight,
.annotationEditorLayer[data-main-rotation="0"]
  :is([data-editor-rotation="0"], [data-editor-rotation="180"])
  > .resizers
  > .resizer.middleLeft,
.annotationEditorLayer[data-main-rotation="90"]
  :is([data-editor-rotation="270"], [data-editor-rotation="90"])
  > .resizers
  > .resizer.middleLeft,
.annotationEditorLayer[data-main-rotation="180"]
  :is([data-editor-rotation="180"], [data-editor-rotation="0"])
  > .resizers
  > .resizer.middleLeft,
.annotationEditorLayer[data-main-rotation="270"]
  :is([data-editor-rotation="90"], [data-editor-rotation="270"])
  > .resizers
  > .resizer.middleLeft {
  cursor: ew-resize;
}

.annotationEditorLayer[data-main-rotation="0"]
  :is([data-editor-rotation="90"], [data-editor-rotation="270"])
  > .resizers
  > .resizer.topLeft,
.annotationEditorLayer[data-main-rotation="90"]
  :is([data-editor-rotation="0"], [data-editor-rotation="180"])
  > .resizers
  > .resizer.topLeft,
.annotationEditorLayer[data-main-rotation="180"]
  :is([data-editor-rotation="270"], [data-editor-rotation="90"])
  > .resizers
  > .resizer.topLeft,
.annotationEditorLayer[data-main-rotation="270"]
  :is([data-editor-rotation="180"], [data-editor-rotation="0"])
  > .resizers
  > .resizer.topLeft,
.annotationEditorLayer[data-main-rotation="0"]
  :is([data-editor-rotation="90"], [data-editor-rotation="270"])
  > .resizers
  > .resizer.bottomRight,
.annotationEditorLayer[data-main-rotation="90"]
  :is([data-editor-rotation="0"], [data-editor-rotation="180"])
  > .resizers
  > .resizer.bottomRight,
.annotationEditorLayer[data-main-rotation="180"]
  :is([data-editor-rotation="270"], [data-editor-rotation="90"])
  > .resizers
  > .resizer.bottomRight,
.annotationEditorLayer[data-main-rotation="270"]
  :is([data-editor-rotation="180"], [data-editor-rotation="0"])
  > .resizers
  > .resizer.bottomRight {
  cursor: nesw-resize;
}

.annotationEditorLayer[data-main-rotation="0"]
  :is([data-editor-rotation="90"], [data-editor-rotation="270"])
  > .resizers
  > .resizer.topMiddle,
.annotationEditorLayer[data-main-rotation="90"]
  :is([data-editor-rotation="0"], [data-editor-rotation="180"])
  > .resizers
  > .resizer.topMiddle,
.annotationEditorLayer[data-main-rotation="180"]
  :is([data-editor-rotation="270"], [data-editor-rotation="90"])
  > .resizers
  > .resizer.topMiddle,
.annotationEditorLayer[data-main-rotation="270"]
  :is([data-editor-rotation="180"], [data-editor-rotation="0"])
  > .resizers
  > .resizer.topMiddle,
.annotationEditorLayer[data-main-rotation="0"]
  :is([data-editor-rotation="90"], [data-editor-rotation="270"])
  > .resizers
  > .resizer.bottomMiddle,
.annotationEditorLayer[data-main-rotation="90"]
  :is([data-editor-rotation="0"], [data-editor-rotation="180"])
  > .resizers
  > .resizer.bottomMiddle,
.annotationEditorLayer[data-main-rotation="180"]
  :is([data-editor-rotation="270"], [data-editor-rotation="90"])
  > .resizers
  > .resizer.bottomMiddle,
.annotationEditorLayer[data-main-rotation="270"]
  :is([data-editor-rotation="180"], [data-editor-rotation="0"])
  > .resizers
  > .resizer.bottomMiddle {
  cursor: ew-resize;
}

.annotationEditorLayer[data-main-rotation="0"]
  :is([data-editor-rotation="90"], [data-editor-rotation="270"])
  > .resizers
  > .resizer.topRight,
.annotationEditorLayer[data-main-rotation="90"]
  :is([data-editor-rotation="0"], [data-editor-rotation="180"])
  > .resizers
  > .resizer.topRight,
.annotationEditorLayer[data-main-rotation="180"]
  :is([data-editor-rotation="270"], [data-editor-rotation="90"])
  > .resizers
  > .resizer.topRight,
.annotationEditorLayer[data-main-rotation="270"]
  :is([data-editor-rotation="180"], [data-editor-rotation="0"])
  > .resizers
  > .resizer.topRight,
.annotationEditorLayer[data-main-rotation="0"]
  :is([data-editor-rotation="90"], [data-editor-rotation="270"])
  > .resizers
  > .resizer.bottomLeft,
.annotationEditorLayer[data-main-rotation="90"]
  :is([data-editor-rotation="0"], [data-editor-rotation="180"])
  > .resizers
  > .resizer.bottomLeft,
.annotationEditorLayer[data-main-rotation="180"]
  :is([data-editor-rotation="270"], [data-editor-rotation="90"])
  > .resizers
  > .resizer.bottomLeft,
.annotationEditorLayer[data-main-rotation="270"]
  :is([data-editor-rotation="180"], [data-editor-rotation="0"])
  > .resizers
  > .resizer.bottomLeft {
  cursor: nwse-resize;
}

.annotationEditorLayer[data-main-rotation="0"]
  :is([data-editor-rotation="90"], [data-editor-rotation="270"])
  > .resizers
  > .resizer.middleRight,
.annotationEditorLayer[data-main-rotation="90"]
  :is([data-editor-rotation="0"], [data-editor-rotation="180"])
  > .resizers
  > .resizer.middleRight,
.annotationEditorLayer[data-main-rotation="180"]
  :is([data-editor-rotation="270"], [data-editor-rotation="90"])
  > .resizers
  > .resizer.middleRight,
.annotationEditorLayer[data-main-rotation="270"]
  :is([data-editor-rotation="180"], [data-editor-rotation="0"])
  > .resizers
  > .resizer.middleRight,
.annotationEditorLayer[data-main-rotation="0"]
  :is([data-editor-rotation="90"], [data-editor-rotation="270"])
  > .resizers
  > .resizer.middleLeft,
.annotationEditorLayer[data-main-rotation="90"]
  :is([data-editor-rotation="0"], [data-editor-rotation="180"])
  > .resizers
  > .resizer.middleLeft,
.annotationEditorLayer[data-main-rotation="180"]
  :is([data-editor-rotation="270"], [data-editor-rotation="90"])
  > .resizers
  > .resizer.middleLeft,
.annotationEditorLayer[data-main-rotation="270"]
  :is([data-editor-rotation="180"], [data-editor-rotation="0"])
  > .resizers
  > .resizer.middleLeft {
  cursor: ns-resize;
}

.annotationEditorLayer
  :is([data-main-rotation="0"]
    [data-editor-rotation="90"], [data-main-rotation="90"]
    [data-editor-rotation="0"], [data-main-rotation="180"]
    [data-editor-rotation="270"], [data-main-rotation="270"]
    [data-editor-rotation="180"])
  .altText {
  rotate: 270deg;
}

[dir="ltr"]
  .annotationEditorLayer
  :is([data-main-rotation="0"]
    [data-editor-rotation="90"], [data-main-rotation="90"]
    [data-editor-rotation="0"], [data-main-rotation="180"]
    [data-editor-rotation="270"], [data-main-rotation="270"]
    [data-editor-rotation="180"])
  .altText {
  inset-inline-start: calc(100% - 8px);
}

[dir="ltr"]
  .annotationEditorLayer
  :is([data-main-rotation="0"]
    [data-editor-rotation="90"], [data-main-rotation="90"]
    [data-editor-rotation="0"], [data-main-rotation="180"]
    [data-editor-rotation="270"], [data-main-rotation="270"]
    [data-editor-rotation="180"])
  .altText.small {
  inset-inline-start: calc(100% + 8px);
  inset-block-start: 100%;
}

[dir="rtl"]
  .annotationEditorLayer
  :is([data-main-rotation="0"]
    [data-editor-rotation="90"], [data-main-rotation="90"]
    [data-editor-rotation="0"], [data-main-rotation="180"]
    [data-editor-rotation="270"], [data-main-rotation="270"]
    [data-editor-rotation="180"])
  .altText {
  inset-block-end: calc(100% - 8px);
}

[dir="rtl"]
  .annotationEditorLayer
  :is([data-main-rotation="0"]
    [data-editor-rotation="90"], [data-main-rotation="90"]
    [data-editor-rotation="0"], [data-main-rotation="180"]
    [data-editor-rotation="270"], [data-main-rotation="270"]
    [data-editor-rotation="180"])
  .altText.small {
  inset-inline-start: -8px;
  inset-block-start: 0;
}

.annotationEditorLayer
  :is([data-main-rotation="0"]
    [data-editor-rotation="180"], [data-main-rotation="90"]
    [data-editor-rotation="90"], [data-main-rotation="180"]
    [data-editor-rotation="0"], [data-main-rotation="270"]
    [data-editor-rotation="270"])
  .altText {
  rotate: 180deg;

  inset-block-end: calc(100% - 8px);
  inset-inline-start: calc(100% - 8px);
}

.annotationEditorLayer
  :is([data-main-rotation="0"]
    [data-editor-rotation="180"], [data-main-rotation="90"]
    [data-editor-rotation="90"], [data-main-rotation="180"]
    [data-editor-rotation="0"], [data-main-rotation="270"]
    [data-editor-rotation="270"])
  .altText.small {
  inset-inline-start: 100%;
  inset-block-start: -8px;
}

.annotationEditorLayer
  :is([data-main-rotation="0"]
    [data-editor-rotation="270"], [data-main-rotation="90"]
    [data-editor-rotation="180"], [data-main-rotation="180"]
    [data-editor-rotation="90"], [data-main-rotation="270"]
    [data-editor-rotation="0"])
  .altText {
  rotate: 90deg;
}

[dir="ltr"]
  .annotationEditorLayer
  :is([data-main-rotation="0"]
    [data-editor-rotation="270"], [data-main-rotation="90"]
    [data-editor-rotation="180"], [data-main-rotation="180"]
    [data-editor-rotation="90"], [data-main-rotation="270"]
    [data-editor-rotation="0"])
  .altText {
  inset-block-end: calc(100% - 8px);
}

[dir="ltr"]
  .annotationEditorLayer
  :is([data-main-rotation="0"]
    [data-editor-rotation="270"], [data-main-rotation="90"]
    [data-editor-rotation="180"], [data-main-rotation="180"]
    [data-editor-rotation="90"], [data-main-rotation="270"]
    [data-editor-rotation="0"])
  .altText.small {
  inset-inline-start: -8px;
  inset-block-start: 0;
}

[dir="rtl"]
  .annotationEditorLayer
  :is([data-main-rotation="0"]
    [data-editor-rotation="270"], [data-main-rotation="90"]
    [data-editor-rotation="180"], [data-main-rotation="180"]
    [data-editor-rotation="90"], [data-main-rotation="270"]
    [data-editor-rotation="0"])
  .altText {
  inset-inline-start: calc(100% - 8px);
}

[dir="rtl"]
  .annotationEditorLayer
  :is([data-main-rotation="0"]
    [data-editor-rotation="270"], [data-main-rotation="90"]
    [data-editor-rotation="180"], [data-main-rotation="180"]
    [data-editor-rotation="90"], [data-main-rotation="270"]
    [data-editor-rotation="0"])
  .altText.small {
  inset-inline-start: calc(100% + 8px);
  inset-block-start: 100%;
}

.altText {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-inline: 4px;
  width: auto;
  height: 24px;
  min-width: 88px;
  z-index: 1;
  pointer-events: all;

  color: var(--alt-text-fg-color);
  font: menu;
  font-size: 12px;
  border-radius: 4px;
  border: 1px solid var(--alt-text-border-color);
  background-color: var(--alt-text-bg-color);
  box-shadow: var(--alt-text-shadow);

  position: absolute;
  inset-block-end: 8px;
  inset-inline-start: 8px;
}

[dir="ltr"] .altText {
  transform-origin: 0 100%;
}

[dir="rtl"] .altText {
  transform-origin: 100% 100%;
}

.altText.small {
  inset-block-end: unset;
  inset-inline-start: 0;
  inset-block-start: calc(100% + 8px);
}

[dir="ltr"] .altText.small {
  transform-origin: 0 0;
}

[dir="rtl"] .altText.small {
  transform-origin: 100% 0;
}

.altText:hover {
  background-color: var(--alt-text-hover-bg-color);
  border-color: var(--alt-text-hover-border-color);
  color: var(--alt-text-hover-fg-color);
  cursor: pointer;
}

.altText:hover::before {
  background-color: var(--alt-text-hover-fg-color);
}

.altText:active {
  background-color: var(--alt-text-active-bg-color);
  border-color: var(--alt-text-active-border-color);
  color: var(--alt-text-active-fg-color);
}

.altText:active::before {
  background-color: var(--alt-text-active-fg-color);
}

.altText:focus-visible {
  outline: 2px solid var(--alt-text-focus-outline-color);
  border-color: var(--alt-text-focus-border-color);
}

.altText::before {
  content: "";
  //-webkit-mask-image: var(--alt-text-add-image);
  //mask-image: var(--alt-text-add-image);
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  display: inline-block;
  width: 12px;
  height: 13px;
  background-color: var(--alt-text-fg-color);
  margin-inline-end: 4px;
}

//.altText.done::before {
//  -webkit-mask-image: var(--alt-text-done-image);
//  mask-image: var(--alt-text-done-image);
//}

.altText .tooltip {
  display: none;
}

.altText .tooltip.show {
  --alt-text-tooltip-bg: #f0f0f4;
  --alt-text-tooltip-fg: #15141a;
  --alt-text-tooltip-border: #8f8f9d;
  --alt-text-tooltip-shadow: 0px 2px 6px 0px rgba(58, 57, 68, 0.2);

  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: calc(100% + 2px);
  inset-inline-start: 0;
  padding-block: 2px 3px;
  padding-inline: 3px;
  max-width: 300px;
  width: -moz-max-content;
  width: max-content;
  height: auto;
  font-size: 12px;

  border: 0.5px solid var(--alt-text-tooltip-border);
  background: var(--alt-text-tooltip-bg);
  box-shadow: var(--alt-text-tooltip-shadow);
  color: var(--alt-text-tooltip-fg);

  pointer-events: none;
}

@media (prefers-color-scheme: dark) {
  .altText .tooltip.show {
    --alt-text-tooltip-bg: #1c1b22;
    --alt-text-tooltip-fg: #fbfbfe;
    --alt-text-tooltip-shadow: 0px 2px 6px 0px #15141a;
  }
}

@media screen and (forced-colors: active) {
  .altText .tooltip.show {
    --alt-text-tooltip-bg: Canvas;
    --alt-text-tooltip-fg: CanvasText;
    --alt-text-tooltip-border: CanvasText;
    --alt-text-tooltip-shadow: none;
  }
}

#altTextDialog {
  --dialog-bg-color: white;
  --dialog-border-color: white;
  --dialog-shadow: 0 2px 14px 0 rgba(58, 57, 68, 0.2);
  --text-primary-color: #15141a;
  --text-secondary-color: #5b5b66;
  --hover-filter: brightness(0.9);
  --focus-ring-color: #0060df;
  --focus-ring-outline: 2px solid var(--focus-ring-color);

  --textarea-border-color: #8f8f9d;
  --textarea-bg-color: white;
  --textarea-fg-color: var(--text-secondary-color);

  --radio-bg-color: #f0f0f4;
  --radio-checked-bg-color: #fbfbfe;
  --radio-border-color: #8f8f9d;
  --radio-checked-border-color: #0060df;

  --button-cancel-bg-color: #f0f0f4;
  --button-cancel-fg-color: var(--text-primary-color);
  --button-cancel-border-color: var(--button-cancel-bg-color);
  --button-cancel-hover-bg-color: var(--button-cancel-bg-color);
  --button-cancel-hover-fg-color: var(--button-cancel-fg-color);
  --button-cancel-hover-border-color: var(--button-cancel-hover-bg-color);

  --button-save-bg-color: #0060df;
  --button-save-fg-color: #fbfbfe;
  --button-save-hover-bg-color: var(--button-save-bg-color);
  --button-save-hover-fg-color: var(--button-save-fg-color);
  --button-save-hover-border-color: var(--button-save-hover-bg-color);
  --button-save-disabled-bg-color: var(--button-save-bg-color);
  --button-save-disabled-fg-color: var(--button-save-fg-color);
  --button-save-disabled-opacity: 0.4;

  font: message-box;
  font-size: 13px;
  font-weight: 400;
  line-height: 150%;
  border-radius: 4px;
  padding: 12px 16px;
  border: 1px solid var(--dialog-border-color);
  background: var(--dialog-bg-color);
  color: var(--text-primary-color);
  box-shadow: var(--dialog-shadow);
}

@media (prefers-color-scheme: dark) {
  #altTextDialog {
    --dialog-bg-color: #1c1b22;
    --dialog-border-color: #1c1b22;
    --dialog-shadow: 0 2px 14px 0 #15141a;
    --text-primary-color: #fbfbfe;
    --text-secondary-color: #cfcfd8;
    --focus-ring-color: #00ddff;
    --hover-filter: brightness(1.4);

    --textarea-bg-color: #42414d;

    --radio-bg-color: #2b2a33;
    --radio-checked-bg-color: #15141a;
    --radio-checked-border-color: #00ddff;

    --button-cancel-bg-color: #2b2a33;
    --button-save-bg-color: #00ddff;
    --button-save-fg-color: #15141a;
  }
}

@media screen and (forced-colors: active) {
  #altTextDialog {
    --dialog-bg-color: Canvas;
    --dialog-border-color: CanvasText;
    --dialog-shadow: none;
    --text-primary-color: CanvasText;
    --text-secondary-color: CanvasText;
    --hover-filter: none;
    --focus-ring-color: ButtonBorder;

    --textarea-border-color: ButtonBorder;
    --textarea-bg-color: Field;
    --textarea-fg-color: ButtonText;

    --radio-bg-color: ButtonFace;
    --radio-checked-bg-color: ButtonFace;
    --radio-border-color: ButtonText;
    --radio-checked-border-color: ButtonText;

    --button-cancel-bg-color: ButtonFace;
    --button-cancel-fg-color: ButtonText;
    --button-cancel-border-color: ButtonText;
    --button-cancel-hover-bg-color: AccentColor;
    --button-cancel-hover-fg-color: AccentColorText;

    --button-save-bg-color: ButtonText;
    --button-save-fg-color: ButtonFace;
    --button-save-hover-bg-color: AccentColor;
    --button-save-hover-fg-color: AccentColorText;
    --button-save-disabled-bg-color: GrayText;
    --button-save-disabled-fg-color: Canvas;
    --button-save-disabled-opacity: 1;
  }
}

//
//#altTextDialog::backdrop {
//  /* This is needed to avoid to darken the image the user want to describe. */
//  -webkit-mask: url(#alttext-manager-mask);
//  mask: url(#alttext-manager-mask);
//}

#altTextDialog.positioned {
  margin: 0;
}

#altTextDialog #altTextContainer {
  width: 300px;
  height: -moz-fit-content;
  height: fit-content;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

#altTextDialog #altTextContainer *:focus-visible {
  outline: var(--focus-ring-outline);
  outline-offset: 2px;
}

#altTextDialog #altTextContainer .radio {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

#altTextDialog #altTextContainer .radio .radioButton {
  display: flex;
  gap: 8px;
  align-self: stretch;
  align-items: center;
}

#altTextDialog #altTextContainer .radio .radioButton input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: var(--radio-bg-color);
  border: 1px solid var(--radio-border-color);
}

#altTextDialog #altTextContainer .radio .radioButton input:hover {
  filter: var(--hover-filter);
}

#altTextDialog #altTextContainer .radio .radioButton input:checked {
  background-color: var(--radio-checked-bg-color);
  border: 4px solid var(--radio-checked-border-color);
}

#altTextDialog #altTextContainer .radio .radioLabel {
  display: flex;
  padding-inline-start: 24px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}

#altTextDialog #altTextContainer .radio .radioLabel span {
  flex: 1 0 0;
  font-size: 11px;
  color: var(--text-secondary-color);
}

#altTextDialog #altTextContainer #overallDescription {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
}

#altTextDialog #altTextContainer #overallDescription span {
  align-self: stretch;
}

#altTextDialog #altTextContainer #overallDescription .title {
  font-size: 13px;
  font-style: normal;
  font-weight: 590;
}

#altTextDialog #altTextContainer #addDescription {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 8px;
}

#altTextDialog #altTextContainer #addDescription .descriptionArea {
  flex: 1;
  padding-inline: 24px 10px;
}

#altTextDialog #altTextContainer #addDescription .descriptionArea textarea {
  font: inherit;
  width: 100%;
  min-height: 75px;
  padding: 8px;
  resize: none;
  margin: 0;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid var(--textarea-border-color);
  background: var(--textarea-bg-color);
  color: var(--textarea-fg-color);
}

#altTextDialog
  #altTextContainer
  #addDescription
  .descriptionArea
  textarea:focus {
  outline-offset: 0;
  border-color: transparent;
}

#altTextDialog
  #altTextContainer
  #addDescription
  .descriptionArea
  textarea:disabled {
  pointer-events: none;
  opacity: 0.4;
}

#altTextDialog #altTextContainer #buttons {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

#altTextDialog #altTextContainer #buttons button {
  border-radius: 4px;
  border: 1px solid;
  font: menu;
  font-weight: 600;
  padding: 4px 16px;
  width: auto;
  height: 32px;
}

#altTextDialog #altTextContainer #buttons button:hover {
  cursor: pointer;
  filter: var(--hover-filter);
}

#altTextDialog #altTextContainer #buttons button#altTextCancel {
  color: var(--button-cancel-fg-color);
  background-color: var(--button-cancel-bg-color);
  border-color: var(--button-cancel-border-color);
}

#altTextDialog #altTextContainer #buttons button#altTextCancel:hover {
  color: var(--button-cancel-hover-fg-color);
  background-color: var(--button-cancel-hover-bg-color);
  border-color: var(--button-cancel-hover-border-color);
}

#altTextDialog #altTextContainer #buttons button#altTextSave {
  color: var(--button-save-hover-fg-color);
  background-color: var(--button-save-hover-bg-color);
  border-color: var(--button-save-hover-border-color);
  opacity: 1;
}

#altTextDialog #altTextContainer #buttons button#altTextSave:hover {
  color: var(--button-save-hover-fg-color);
  background-color: var(--button-save-hover-bg-color);
  border-color: var(--button-save-hover-border-color);
}

#altTextDialog #altTextContainer #buttons button#altTextSave:disabled {
  color: var(--button-save-disabled-fg-color);
  background-color: var(--button-save-disabled-bg-color);
  opacity: var(--button-save-disabled-opacity);
  pointer-events: none;
}

:root {
  --viewer-container-height: 0;
  --pdfViewer-padding-bottom: 0;
  --page-margin: 1px auto -8px;
  --page-border: 9px solid transparent;
  --spreadHorizontalWrapped-margin-LR: -3.5px;
  --loading-icon-delay: 400ms;
}

@media screen and (forced-colors: active) {
  :root {
    --pdfViewer-padding-bottom: 9px;
    --page-margin: 8px auto -1px;
    --page-border: 1px solid CanvasText;
    --spreadHorizontalWrapped-margin-LR: 3.5px;
  }
}

[data-main-rotation="90"] {
  transform: rotate(90deg) translateY(-100%);
}

[data-main-rotation="180"] {
  transform: rotate(180deg) translate(-100%, -100%);
}

[data-main-rotation="270"] {
  transform: rotate(270deg) translateX(-100%);
}

#hiddenCopyElement {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  display: none;
}

.pdfViewer {
  /* Define this variable here and not in :root to avoid to reflow all the UI
     when scaling (see #15929). */
  --scale-factor: 1;

  padding-bottom: var(--pdfViewer-padding-bottom);
}

.pdfViewer .canvasWrapper {
  overflow: hidden;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.pdfViewer .page {
  direction: ltr;
  width: 816px;
  height: 1056px;
  margin: var(--page-margin);
  position: relative;
  overflow: visible;
  border: var(--page-border);
  background-clip: content-box;
  background-color: rgba(255, 255, 255, 1);
}

.pdfViewer .dummyPage {
  position: relative;
  width: 0;
  height: var(--viewer-container-height);
}

.pdfViewer.noUserSelect {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.pdfViewer.removePageBorders .page {
  margin: 0 auto 10px;
  border: none;
}

.pdfViewer.singlePageView {
  display: inline-block;
}

.pdfViewer.singlePageView .page {
  margin: 0;
  border: none;
}

.pdfViewer:is(.scrollHorizontal, .scrollWrapped),
.spread {
  margin-inline: 3.5px;
  text-align: center;
}

.pdfViewer.scrollHorizontal,
.spread {
  white-space: nowrap;
}

.pdfViewer.removePageBorders,
.pdfViewer:is(.scrollHorizontal, .scrollWrapped) .spread {
  margin-inline: 0;
}

.spread :is(.page, .dummyPage),
.pdfViewer:is(.scrollHorizontal, .scrollWrapped) :is(.page, .spread) {
  display: inline-block;
  vertical-align: middle;
}

.spread .page,
.pdfViewer:is(.scrollHorizontal, .scrollWrapped) .page {
  margin-inline: var(--spreadHorizontalWrapped-margin-LR);
}

.pdfViewer.removePageBorders .spread .page,
.pdfViewer.removePageBorders:is(.scrollHorizontal, .scrollWrapped) .page {
  margin-inline: 5px;
}

.pdfViewer .page canvas {
  margin: 0;
  display: block;
}

.pdfViewer .page canvas .structTree {
  contain: strict;
}

.pdfViewer .page canvas[hidden] {
  display: none;
}

.pdfViewer .page canvas[zooming] {
  width: 100%;
  height: 100%;
}

.pdfViewer .page.loadingIcon::after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  //background: url("images/loading-icon.gif") center no-repeat;
  display: none;
  /* Using a delay with background-image doesn't work,
     consequently we use the display. */
  transition-property: display;
  transition-delay: var(--loading-icon-delay);
  z-index: 5;
  contain: strict;
}

.pdfViewer .page.loading::after {
  display: block;
}

.pdfViewer .page:not(.loading)::after {
  transition-property: none;
  display: none;
}

.pdfPresentationMode .pdfViewer {
  padding-bottom: 0;
}

.pdfPresentationMode .spread {
  margin: 0;
}

.pdfPresentationMode .pdfViewer .page {
  margin: 0 auto;
  border: 2px solid transparent;
}

.loading_ellipsis {
  position: relative;
  padding-right: 1.25em;
}

.loading_ellipsis:after {
  overflow: hidden;
  display: inline-block;
  position: absolute;
  vertical-align: bottom;
  animation: ellipsis steps(6, start) 1200ms infinite;
  content: "\2026"; /* ascii code for the ellipsis character */
  width: 0px;
  opacity: 1;
}

@keyframes ellipsis {
  65% {
    width: 1.25em;
  }
  100% {
    width: 1.25em;
    opacity: 0;
  }
}

.pannable_container {
  width: max-content;
  background: linear-gradient(90deg, rgb(178 209 255) 50%, transparent 50%),
    linear-gradient(90deg, rgb(178 209 255) 50%, transparent 50%),
    linear-gradient(0deg, rgb(178 209 255) 50%, transparent 50%),
    linear-gradient(0deg, rgb(178 209 255) 50%, transparent 50%);
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 12px 2px, 12px 2px, 2px 12px, 2px 12px;
  padding: 10px;
  animation: border-dance 75s infinite linear;
}

@keyframes border-dance {
  0% {
    background-position: 0 0, 100% 100%, 0 100%, 100% 0;
  }
  100% {
    background-position: 100% 0, 0 100%, 0 0, 100% 100%;
  }
}

.loading_placeholder {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 4px;
  background-color: var(--copilot-document-viewer-background);
  height: calc(100% - 20px);

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0px,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0)
    );
    animation: 3s ease 0s infinite normal none running shimmer;
    content: "";
  }
}

.loading_placeholder_doc_hidden {
  opacity: 1;
  animation: fade-out 500ms forwards 1000ms;
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.highlight_color {
  opacity: 0;
  animation: bounce-in 400ms forwards;
}

.highlight_color:nth-child(2) {
  animation: bounce-in 400ms ease-in 50ms forwards;
}

.highlight_color:nth-child(3) {
  animation: bounce-in 400ms ease-in 100ms forwards;
}

.highlight_color:nth-child(4) {
  animation: bounce-in 400ms ease-in 150ms forwards;
}

.highlight_color:nth-child(5) {
  animation: bounce-in 400ms ease-in 200ms forwards;
}

@keyframes bounce-in {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
