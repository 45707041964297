:root {
  /* brand foundation */
  --brand-hue: 280;
  --brand-saturation: 5;
  --brand-lightness: 35;

  --brand-orange-hue: 33;
  --brand-orange-saturation: 30;
  --brand-orange-lightness: 50;

  //217
  --brand-hue-light: 217;
  --brand-saturation-light: 43;
  --brand-lightness-light: 96;

  //dark purple
  --action-hue-dark: 280;
  --action-hue-light: 222;
  --action-saturation: 50;
  --action-lightness: 35;

  /* ------> LIGHT <------ */
  --brand-light: hsl(
    var(--brand-hue-light) var(brand-saturation-light)
      var(--brand-lightness-light)
  );

  --text-primary-light: #1c1c1c;
  --text-secondary-light: #525960;
  --text-tertiary-light: hsl(var(--brand-hue-light) 30% 50%);
  --background-light: #ecedef;
  --background-gradient-light: #ecedef;
  //--background-gradient-light: hsl(var(--brand-hue-light) 43% 96%);
  // --background-gradient-light: linear-gradient(
  //  180deg,
  //  hsl(var(--brand-hue-light) 43% 96%) 50%,
  //  hsl(var(--brand-hue-light) 43% 94%) 100%
  //);

  --sidebar-background-light: #fff;
  --sidebar-gradient-light: linear-gradient(
    180deg,
    hsl(var(--brand-hue-light) 43% 96%) 50%,
    hsl(var(--brand-hue-light) 43% 94%) 100%
  );
  //hsl(210deg 96.77% 87.84%);
  --sidebar-element-hover-light: #f4f4f6;
  --sidebar-element-selected-light: #ecedef;
  --border-color-light: #d0d7de;
  --popup-background-light: #fff;
  --popup-element-hover-light: rgb(189 182 192);
  --popup-element-selected-light: #9b949e;
  --primary-highlight-light: hsl(var(--brand-hue-light) 20% 85%);
  --secondary-highlight-light: #fff;
  --secondary-surface-light: hsl(var(--brand-hue-light) 98% 98%);
  --tertiary-highlight-light: hsl(var(--brand-hue-light) 99% 99%);
  --copilot-dialog-select-light: #ecedef;
  --modal-highlight-light: #ecedef;
  --button-color-light: #9b949e;
  --button-color-highlight-light: #5b555e;
  --button-color-hover-light: #7a747d;
  --checkbox-selected-light: var(--button-color-hover-light);
  //--button-color-light: hsl(var(--brand-hue-light) 80% 80%);
  //--button-color-highlight-light: hsl(var(--brand-hue-light) 70% 70%);
  //--button-color-hover-light: hsl(var(--brand-hue-light) 70% 65%);
  --copilot-red-light: #c67e85;
  --copilot-red-hover-light: #be6a72;
  --switch-color-light: #c0b9c3;
  --switch-track-color-light: hsl(var(--brand-hue-light) 24% 88%);
  --action-button-light: hsl(var(--action-hue-light) 50% 35%);
  --action-button-hover-light: hsl(var(--action-hue-light) 50% 30%);
  --bot-message-background-light: #ecedef;
  --user-message-background-light: hsl(var(--brand-hue-light) 91% 91%);
  --user-message-color-light: hsl(var(--brand-hue-light) 45% 10%);
  --user-button-background-color-light: hsl(var(--brand-hue-light) 91% 91%);
  --secondary-source-background-color-light: hsl(
    var(--brand-hue-light) 100% 100%
  );
  --secondary-source-text-color-light: hsl(var(--brand-hue-light) 8% 54%);
  --input-background-color-light: #ecedef;
  --nested-dialog-background-color-light: #fff;
  --input-box-shadow-light: inset 1px 1px 2px 0px
    hsl(var(--brand-hue-light) 20% 75%);
  --copilot-inset-shadow-light: inset 1px 1px 4px 1px hsl(0deg 0% 79.74%),
    inset -1px -1px 4px 1px hsl(0deg 0% 100%);
  --copilot-document-viewer-background-light: hsl(
    var(--brand-hue-light) 100% 100%
  );
  //  copilotFeedbackBorderColor
  //copilotFeedbackDialogBackgroundColor
  //copilotFeedbackDialogSendButton
  //copilotFeedbackOpenDialogButton
  --copilot-feedback-open-dialog-button-light: #9b949e;
  --copilot-feedback-dialog-button-color-light: #fff;
  --copilot-feedback-dialog-background-color-light: #fff;
  --copilot-feedback-dialog-send-button-light: hsl(
    var(--brand-hue-light) 5.03% 35.1%
  );
  --copilot-feedback-border-color-light: #d0d7de;
  --copilot-feedback-placeholder-color-light: #d0d7de;
  --copilot-icon-color-light: #c0b9c3;
  --copilot-feedback-dialog-send-button-color-light: #1c1c1c;

  /* ------> DARK <------ */
  --brand-dark: hsl(
    var(--brand-hue) var(--brand-saturation) var(--brand-lightness)
  );
  --text-primary-dark: hsl(var(--brand-hue) 15% 85%);
  --text-secondary-dark: hsl(var(--brand-hue) 5% 65%);
  --text-tertiary-dark: hsl(var(--brand-hue) 15% 95%);
  --background-dark: hsl(var(--brand-hue) 5% 35%);
  --background-gradient-dark: linear-gradient(
    180deg,
    hsl(var(--brand-hue) 5% 35%) 50%,
    hsl(var(--brand-hue) 5% 33%) 100%
  );

  --sidebar-background-dark: hsl(var(--brand-hue) 10% 25%);
  --sidebar-gradient-dark: linear-gradient(
    180deg,
    hsl(var(--brand-hue) 10% 25% / 1) 50%,
    hsl(var(--brand-hue) 10% 23% / 1) 100%
  );
  --sidebar-element-hover-dark: hsl(var(--brand-hue) 5% 35%);
  --sidebar-element-selected-dark: hsl(var(--brand-hue) 5% 45%);
  --border-color-dark: hsl(var(--brand-hue) 0% 45%);
  --popup-background-dark: hsl(var(--brand-hue) 5% 45%);
  --popup-element-hover-dark: hsl(var(--brand-hue) 5% 45%);
  --popup-element-selected-dark: hsl(var(--brand-hue) 7% 60%);
  --primary-highlight-dark: hsl(var(--brand-hue) 5% 60%);
  --secondary-highlight-dark: hsl(var(--brand-hue) 5% 45%);
  --secondary-surface-dark: hsl(var(--brand-hue) 5% 45%);
  --tertiary-highlight-dark: hsl(var(--brand-hue) 7% 60%);
  --copilot-dialog-select-dark: hsl(var(--brand-hue) 5% 37%);
  --modal-highlight-dark: hsl(var(--brand-hue) 5% 45%);
  --button-color-dark: hsl(var(--brand-orange-hue) 30% 50%);
  --button-color-highlight-dark: hsl(var(--brand-orange-hue) 40% 60%);
  --button-color-hover-dark: hsl(var(--brand-orange-hue) 40% 50%);
  --checkbox-selected-dark: var(--text-primary-dark);
  --copilot-red-dark: hsl(0deg 30% 50%);
  --copilot-red-hover-dark: hsl(0deg 30% 40%);
  --switch-color-dark: hsl(var(--brand-hue) 15% 85%);
  --switch-track-color-dark: hsl(var(--brand-hue) 7% 60%);
  --action-button-dark: hsl(var(--action-hue-dark) 35% 35%);
  --action-button-hover-dark: hsl(var(--action-hue-dark) 35% 30%);
  --bot-message-background-dark: hsl(var(--brand-hue) 5% 45%);
  --user-message-background-dark: hsl(var(--brand-orange-hue) 30% 50%);
  --user-message-color-dark: hsl(var(--brand-hue-light) 100% 100%);
  --user-button-background-color-dark: hsl(var(--brand-orange-hue) 30% 50%);
  --secondary-source-background-color-dark: hsl(var(--brand-hue) 5% 65%);
  --secondary-source-text-color-dark: hsl(var(--brand-hue) 5% 35%);
  --input-background-color-dark: hsl(var(--brand-hue) 5% 37%);
  --nested-dialog-background-color-dark: hsl(var(--brand-hue) 5% 37%);
  --input-box-shadow-dark: inset 1px 1px 2px 0px hsl(var(--brand-hue) 1.5% 32%);
  --copilot-inset-shadow-dark: inset 1px 1px 4px 1px hsl(280 10% 12% / 1),
    inset -1px -1px 4px 1px hsl(280deg 5.94% 55.79%);
  --copilot-document-viewer-background-dark: hsl(var(--brand-hue) 10% 25%);
  --copilot-feedback-open-dialog-button-dark: hsl(var(--brand-hue) 5% 35%);
  --copilot-feedback-dialog-button-color-dark: #fff;
  --copilot-feedback-dialog-background-color-dark: hsl(
    var(--brand-hue) 5.03% 35.1%
  );
  --copilot-feedback-dialog-send-button-dark: hsl(var(--brand-hue) 5.03% 35.1%);
  --copilot-feedback-dialog-send-button-color-dark: #fff;
  --copilot-feedback-border-color-dark: #737373;
  --copilot-feedback-placeholder-color-dark: var(--secondary-text-color-dark);
  --copilot-icon-color-dark: #c0b9c3;
  //hsl(var(--brand-hue) 4.72% 36.63%);
  --ease-3: cubic-bezier(0.25, 0, 0.3, 1);
  --ease-in-out-5: cubic-bezier(0.9, 0, 0.1, 1);
  --ease-elastic-3: cubic-bezier(0.5, -0.5, 0.1, 1.5);
  --ease-elastic-4: cubic-bezier(0.5, -0.7, 0.1, 1.5);
}

.copilot {
  &[data-theme="light"] {
    color-scheme: light;

    --brand: var(--brand-light);
    --text-primary: var(--text-primary-light);
    --text-secondary: var(--text-secondary-light);
    --text-tertiary: var(--text-tertiary-light);
    --background: var(--background-light);
    --background-gradient: var(--background-gradient-light);
    --sidebar-background: var(--sidebar-background-light);
    --sidebar-gradient: var(--sidebar-gradient-light);
    --sidebar-element-hover: var(--sidebar-element-hover-light);
    --sidebar-element-selected: var(--sidebar-element-selected-light);
    --border-color: var(--border-color-light);
    --popup-background: var(--popup-background-light);
    --popup-element-hover: var(--popup-element-hover-light);
    --popup-element-selected: var(--popup-element-selected-light);
    --highlight: var(--primary-highlight-light);
    --secondary-highlight: var(--secondary-highlight-light);
    --secondary-surface: var(--secondary-surface-light);
    --tertiary-highlight: var(--tertiary-highlight-light);
    --copilot-dialog-select: var(--copilot-dialog-select-light);
    --modal-highlight: var(--modal-highlight-light);
    --button-color: var(--button-color-light);
    --button-color-highlight: var(--button-color-highlight-light);
    --button-color-hover: var(--button-color-hover-light);
    --checkbox-selected: var(--checkbox-selected-light);
    --copilot-red: var(--copilot-red-light);
    --copilot-red-hover: var(--copilot-red-hover-light);
    --switch-color: var(--switch-color-light);
    --switch-track-color: var(--switch-track-color-light);
    --chat-background: #fff;
    --chat-message-text-color: var(--text-primary-light);
    --action-button: var(--action-button-light);
    --action-button-hover: var(--action-button-hover-light);
    --bot-message-background: var(--bot-message-background-light);
    --user-message-background: var(--user-message-background-light);
    --user-button-background-color: var(--user-button-background-color-light);
    --user-message-color: var(--user-message-color-light);
    --secondary-source-background-color: var(
      --secondary-source-background-color-light
    );
    --secondary-source-text-color: var(--secondary-source-text-color-light);
    --input-background-color: var(--input-background-color-light);
    --nested-dialog-background-color: var(
      --dialog-dialog-background-color-light
    );
    --input-box-shadow: var(--input-box-shadow-light);
    --copilot-inset-shadow: var(--copilot-inset-shadow-light);
    --copilot-document-viewer-background: var(
      --copilot-document-viewer-background-light
    );
    --copilot-feedback-open-dialog-button: var(
      --copilot-feedback-open-dialog-button-light
    );
    --copilot-feedback-dialog-button-color: var(
      --copilot-feedback-dialog-button-color-light
    );
    --copilot-feedback-dialog-background-color: var(
      --copilot-feedback-dialog-background-color-light
    );
    --copilot-feedback-dialog-send-button: var(
      --copilot-feedback-dialog-send-button-light
    );
    --copilot-feedback-border-color: var(--copilot-feedback-border-color-light);
    --copilot-feedback-placeholder-color: var(
      --copilot-feedback-placeholder-color-light
    );
    --copilot-icon-color: var(--copilot-icon-color-light);
    //--surface-shadow: var(--surface-shadow-light);
    //--shadow-strength: var(--shadow-strength-light);
  }

  &[data-theme="dark"] {
    color-scheme: dark;

    --brand: var(--brand-dark);
    --text-primary: var(--text-primary-dark);
    --text-secondary: var(--text-secondary-dark);
    --text-tertiary: var(--text-tertiary-dark);
    --background: var(--background-dark);
    --background-gradient: var(--background-gradient-dark);
    --sidebar-background: var(--sidebar-background-dark);
    --sidebar-gradient: var(--sidebar-gradient-dark);
    --sidebar-element-hover: var(--sidebar-element-hover-dark);
    --sidebar-element-selected: var(--sidebar-element-selected-dark);
    --border-color: var(--border-color-dark);
    --popup-background: var(--popup-background-dark);
    --popup-element-hover: var(--popup-element-hover-dark);
    --popup-element-selected: var(--popup-element-selected-dark);
    --highlight: var(--primary-highlight-dark);
    --secondary-highlight: var(--secondary-highlight-dark);
    --secondary-surface: var(--secondary-surface-dark);
    --tertiary-highlight: var(--tertiary-highlight-dark);
    --copilot-dialog-select: var(--copilot-dialog-select-dark);
    --modal-highlight: var(--modal-highlight-dark);
    --button-color: var(--button-color-dark);
    --button-color-highlight: var(--button-color-highlight-dark);
    --button-color-hover: var(--button-color-hover-dark);
    --checkbox-selected: var(--checkbox-selected-dark);
    --copilot-red: var(--copilot-red-dark);
    --copilot-red-hover: var(--copilot-red-hover-dark);
    --switch-color: var(--switch-color-dark);
    --switch-track-color: var(--switch-track-color-dark);
    --chat-background: transparent;
    --chat-message-text-color: #fff;
    --action-button: var(--action-button-dark);
    --action-button-hover: var(--action-button-hover-dark);
    --bot-message-background: var(--bot-message-background-dark);
    --user-message-background: var(--user-message-background-dark);
    --user-button-background-color: var(--user-button-background-color-dark);
    --user-message-color: var(--user-message-color-dark);
    --secondary-source-background-color: var(
      --secondary-source-background-color-dark
    );
    --secondary-source-text-color: var(--secondary-source-text-color-dark);
    --input-background-color: var(--input-background-color-dark);
    --nested-dialog-background-color: var(
      --dialog-dialog-background-color-dark
    );
    --input-box-shadow: var(--input-box-shadow-dark);
    --copilot-inset-shadow: var(--copilot-inset-shadow-dark);
    --copilot-document-viewer-background: var(
      --copilot-document-viewer-background-dark
    );
    --copilot-feedback-open-dialog-button: var(
      --copilot-feedback-open-dialog-button-dark
    );
    --copilot-feedback-dialog-button-color: #fff;
    --copilot-feedback-dialog-background-color: var(
      --copilot-feedback-dialog-background-color-dark
    );
    --copilot-feedback-dialog-send-button: var(
      --copilot-feedback-dialog-send-button-dark
    );
    --copilot-feedback-border-color: var(--copilot-feedback-border-color-dark);
    --copilot-feedback-placeholder-color: var(
      --copilot-feedback-placeholder-color-dark
    );
    --copilot-icon-color: var(--copilot-icon-color-dark);
    //--surface-shadow: var(--surface-shadow-dark);
    //--shadow-strength: var(--shadow-strength-dark);
  }

  @supports not (color-scheme: dark) {
    &[data-theme="dark"] {
      background: #111;
    }
  }
  width: 100%;
  overflow: hidden;
  color: var(--text-primary);
  background: var(--background-gradient);

  .brand {
    color: var(--brand);
    background-color: var(--brand);
  }

  .sidebar {
    //background: var(--sidebar-gradient);
    background: var(--sidebar-background);

    &::-webkit-scrollbar-thumb,
    & *::-webkit-scrollbar-thumb {
      background-color: var(--sidebar-element-selected);
    }

    &::-webkit-scrollbar-track,
    & *::-webkit-scrollbar-track {
      background: transparent;
      border: 0px none #ffffff;
      border-radius: 12px;
    }
  }

  .document_tool {
    &::-webkit-scrollbar-thumb,
    & *::-webkit-scrollbar-thumb {
      background-color: var(--sidebar-element-selected);
    }

    &::-webkit-scrollbar-track,
    & *::-webkit-scrollbar-track {
      background: transparent;
      border: 0px none #ffffff;
      border-radius: 12px;
    }
  }

  #pages {
    &::-webkit-scrollbar-thumb,
    & *::-webkit-scrollbar-thumb {
      background-color: var(--sidebar-element-selected);
    }

    &::-webkit-scrollbar-track,
    & *::-webkit-scrollbar-track {
      background: transparent;
      border: 0px none #ffffff;
      border-radius: 12px;
    }
  }

  .document-resizing {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: transparent;
      z-index: 100;
      opacity: 0.5;
    }
  }

  .resizable-child-container {
    height: 100%;
    overflow: hidden;
  }

  .sidebar_toggle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    transition: background-color 0.2s ease-out, cursor 0.2s ease-out;
    position: relative;
    flex-shrink: 0;

    & > button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 4px;
      transition: background-color 0.2s ease-out, cursor 0.2s ease-out;

      & > svg {
        fill: var(--highlight);
        width: 20px;
        height: 20px;
        transition: fill 0.2s ease-out, transform 200ms ease-out;

        &:hover {
          fill: var(--text-primary);
        }
      }

      &:hover {
        cursor: pointer;
        background-color: transparent;

        & > svg {
          fill: var(--text-primary);
        }
      }
    }
  }

  .popup {
    background-color: var(--popup);
  }

  .highlight {
    background-color: var(--highlight);
  }

  .text-primary {
    color: var(--text-primary);
  }

  .text-secondary {
    color: var(--text2);
  }

  //HALASOASO
  .sun-and-moon > :is(.moon, .sun, .sun-beams) {
    transform-origin: center center;
  }

  .sun-and-moon > :is(.moon, .sun) {
    fill: var(--icon-fill);
  }

  .theme-toggle:is(:hover, :focus-visible) > .sun-and-moon > :is(.moon, .sun) {
    fill: var(--icon-fill-hover);
  }

  .sun-and-moon > .sun-beams {
    stroke: var(--icon-fill);
    stroke-width: 2px;
  }

  .theme-toggle:is(:hover, :focus-visible) .sun-and-moon > .sun-beams {
    stroke: var(--icon-fill-hover);
  }

  &[data-theme="dark"] .sun-and-moon > .sun {
    transform: scale(1.75);
  }

  &[data-theme="dark"] .sun-and-moon > .sun-beams {
    opacity: 0;
  }

  &[data-theme="dark"] .sun-and-moon > .moon > circle {
    transform: translate(-7px);
  }

  @supports (cx: 1) {
    &[data-theme="dark"] .sun-and-moon > .moon > circle {
      transform: translate(0);
      cx: 17;
    }
  }

  @media (prefers-reduced-motion: no-preference) {
    .sun-and-moon > .sun {
      transition: transform 0.5s var(--ease-elastic-3);
    }
    .sun-and-moon > .sun-beams {
      transition: transform 0.5s var(--ease-elastic-4),
        opacity 0.5s var(--ease-3);
    }
    .sun-and-moon .moon > circle {
      transition: transform 0.25s var(--ease-out-5);
    }
    @supports (cx: 1) {
      .sun-and-moon .moon > circle {
        transition: cx 0.25s var(--ease-out-5);
      }
    }
    &[data-theme="dark"] .sun-and-moon > .sun {
      transform: scale(1.75);
      transition-timing-function: var(--ease-3);
      transition-duration: 0.25s;
    }
    &[data-theme="dark"] .sun-and-moon > .sun-beams {
      transform: rotate(-25deg);
      transition-duration: 0.15s;
    }
    &[data-theme="dark"] .sun-and-moon > .moon > circle {
      transition-delay: 0.25s;
      transition-duration: 0.5s;
    }
  }

  .theme-toggle {
    //--size: 2rem;
    --icon-fill: hsl(210 10% 30%);
    --icon-fill-hover: hsl(210 10% 15%);
    background: none;
    border: none;
    padding: 0;
    //inline-size: var(--size);
    //block-size: var(--size);
    aspect-ratio: 1;
    border-radius: 50%;
    cursor: pointer;
    touch-action: manipulation;
    -webkit-tap-highlight-color: transparent;
    outline-offset: 5px;
  }

  .theme-toggle > svg {
    //inline-size: 100%;
    //block-size: 100%;
    stroke-linecap: round;
  }

  &[data-theme="dark"] .theme-toggle {
    --icon-fill: hsl(210 10% 70%);
    --icon-fill-hover: hsl(210 15% 90%);
  }

  //.documents-tree {
  //  background-color: red;
  //
  //  .rst__row {
  //    .rst__moveHandle {
  //      background-color: var(--sidebar-element-selected);
  //    }
  //
  //    .rst__rowContents {
  //      background-color: var(--sidebar-element-selected);
  //    }
  //  }
  //}

  @media (hover: none) {
    .theme-toggle {
      --size: 48px;
    }
  }

  .grow-animation {
    width: 0;
    animation: __grow_width 0.5s ease-in-out forwards;
  }

  .shrink-animation {
    width: 100%;
    animation: __shrink_width 0.5s ease-in-out forwards;

    * {
      opacity: 0;
    }
  }

  @keyframes __grow_width {
    to {
      width: 100%;
    }
  }
  @keyframes __shrink_width {
    to {
      width: 0;
    }
  }
}

.copilot-chatIframe {
  transition: opacity 0.25s ease-out;
  opacity: 0; /* Initial state */
}

.list-element {
  width: 98%;
}
