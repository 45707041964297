@import "../_vars.scss";

.smallEntry {
  font-size: 0.7rem;
}

.entry {
  position: relative;
  text-align: left;
  display: flex;
  flex-direction: column;
  padding: 5px 8px;
  margin-bottom: 4px;
  word-break: normal;
  border-radius: 9px;

  &.media {
    padding: 0;
  }

  input {
    background: none;
    border: none;
    outline: none;

    &[disabled] {
      cursor: text;
    }

    &::placeholder {
      //color: #e0e0e0;
      color: #fff;
    }
  }

  .dragIcon,
  .expandIcon {
    z-index: 11;
    opacity: 0;
    position: absolute;
    transition: opacity 0.2s, transform 0.2s;
    right: 4px;
    bottom: 2px;
    height: 22px;
    padding: 4px;
  }

  .editIcon {
    z-index: 11;
    opacity: 0;
    position: absolute;
    transition: opacity 0.2s, transform 0.2s;
    right: 24px;
    bottom: 2px;
    height: 22px;
    padding: 4px;
  }

  .dragIcon,
  .editIcon {
    &:hover {
      background: rgba(167, 182, 194, 0.3);
    }

    &:active {
      background: rgba(115, 134, 148, 0.3);
    }
  }

  .expandIcon {
    &:hover {
      filter: brightness(1.5);
    }

    &:active {
      filter: brightness(1.5);
    }

    right: -18px;
    border-radius: 0 30px 30px 0;
    height: 100%;
    top: 0;
    background-color: inherit;
  }

  &:hover .controlGroup {
    opacity: 1;
    pointer-events: auto;
    transform: translateX(-10px);
  }

  .iconStack {
    opacity: 0;
    transform: translateX(10px);
  }

  &:hover .iconStack.visible {
    opacity: 1;
    transform: translateX(0px);
  }

  &:hover .dragIcon.visible,
  &:hover .editIcon.visible,
  &:hover .expandIcon.visible {
    opacity: 1;
  }

  .handle {
    // because in production build it doesn't opverride default styles otherwise
    // maybe switch to not using default react-flow styling
    border: none;
    //background: orange ;
    background-color: $node-handle-color;
    //right: -7px;
    width: 8px;
    height: 8px;

    z-index: 999;

    &:hover {
      transform: translateY(-49%) rotate(45deg) scale(2) !important;
      border: 1px inset #ffcc82 !important;
    }

    //&.conditionTrue {
    //  background: darkgreen !important;
    //  border-radius: 10px !important;
    //  height: 10px;
    //  width: 10px;
    //}
    //
    //&.conditionFalse {
    //  background: darkred !important;
    //  border-radius: 10px !important;
    //  height: 10px;
    //  width: 10px;
    //}
  }
}

.container {
  cursor: default;
  background: $node-content-background-color;
  //background: var(--node-color);
  padding: 8px;
  border-radius: 0px 0px 12px 12px;

  > div + div {
    margin-top: 8px;
  }

  .responses,
  .ticketCategories,
  .decisionTree {
    margin-bottom: 2px;

    .entry {
      background-color: $node-background-color;
      border-radius: 6px;
      border: 1px solid var(--border-color-base);
    }

    .select {
      font-size: 0.75rem;
      border-radius: 9px;
      background-color: $node-background-color;
    }
  }

  .faq {
    margin-bottom: 2px;

    .entry {
      font-size: 0.75rem;
      text-align: left;
      background-color: $node-background-color;
    }

    .select {
      font-size: 0.75rem;
      border-radius: 9px;
      background-color: $node-background-color;

      .react-dropdown-select-content {
        background-color: red;
      }
    }
  }

  .config {
    margin: 0 5px;

    label {
      text-transform: unset;
    }
  }

  .outcomes {
    .entry {
      padding: 10px 8px;
      background-color: $node-background-color;
      border-radius: 9px;
      border: 1px solid var(--border-color-base);

      span {
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  .slots {
    margin-bottom: 2px;
    margin-top: 5px;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    .header {
      color: $node-slot-header-font-color;
      font-size: 10px;
      text-align: center;
      padding: 0 0 5px 0;
    }

    .entry {
      background-color: $node-slot-background-color;
      color: $node-slot-font-color;
      font-family: "Courier New", Courier, "Lucida Sans Typewriter",
        "Lucida Typewriter", monospace;
      font-weight: bold;
      padding: 8px 12px;
      text-overflow: ellipsis;
      overflow: hidden;

      .set_slot_input {
        padding: 2px;
        background-color: $node-label-background-color;
        width: 80%;
      }

      .set_slot_area {
        padding: 2px;
        background-color: $node-label-background-color;
        width: 100%;
        min-height: 20px;
        height: 20px;
        resize: vertical;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        border: none;
        outline: none;
      }
    }
  }

  .buttons {
    color: $node-button-font-color;
    margin-bottom: 2px;
    margin-top: 5px;

    .header {
      color: $node-slot-header-font-color;
      font-size: 10px;
      text-align: center;
      padding: 0 0 5px 0;
    }

    .entry {
      background-color: $node-button-background-color;
      position: relative;

      .expandIcon {
      }

      .dragIcon,
      .editIcon {
        filter: brightness(2);

        &:hover {
          background: rgba(167, 182, 194, 0.1);
        }

        &:active {
          background: rgba(115, 134, 148, 0.1);
        }
      }

      &.datePicker {
        text-align: center;

        .icon {
          filter: brightness(3);
        }

        input {
          text-align: center;
        }
      }

      &.default {
        background-color: $node-default-button-background-color;
      }
    }
  }

  .textInputs {
    color: $node-text-input-font-color;
    margin-bottom: 2px;
    margin-top: 5px;

    .entry {
      background-color: $node-text-input-background-color;
      position: relative;

      .expandIcon {
      }

      .dragIcon,
      .editIcon {
        filter: brightness(2);

        &:hover {
          background: rgba(167, 182, 194, 0.1);
        }

        &:active {
          background: rgba(115, 134, 148, 0.1);
        }
      }

      &.datePicker {
        text-align: center;

        .icon {
          filter: brightness(3);
        }

        input {
          text-align: center;
        }
      }
    }
  }

  .equalCases {
    color: $node-equal-case-font-color;
    margin-bottom: 15px;
    margin-top: 5px;

    .header {
      color: $node-slot-header-font-color;
      font-size: 10px;
      text-align: center;
      padding: 0 0 5px 0;
    }

    .entry {
      background-color: $node-equal-case-background-color;
      position: relative;

      .expandIcon {
      }

      .dragIcon,
      .editIcon {
        filter: brightness(2);

        &:hover {
          background: rgba(167, 182, 194, 0.1);
        }

        &:active {
          background: rgba(115, 134, 148, 0.1);
        }
      }

      &.datePicker {
        text-align: center;

        .icon {
          filter: brightness(3);
        }

        input {
          text-align: center;
        }
      }
    }
  }

  .containCases {
    color: $node-contain-case-font-color;
    margin-bottom: 15px;
    margin-top: 5px;

    .header {
      color: $node-slot-header-font-color;
      font-size: 10px;
      text-align: center;
      padding: 0 0 5px 0;
    }

    .entry {
      background-color: $node-contain-case-background-color;
      position: relative;

      .expandIcon {
      }

      .dragIcon,
      .editIcon {
        filter: brightness(2);

        &:hover {
          background: rgba(167, 182, 194, 0.1);
        }

        &:active {
          background: rgba(115, 134, 148, 0.1);
        }
      }

      &.datePicker {
        text-align: center;

        .icon {
          filter: brightness(3);
        }

        input {
          text-align: center;
        }
      }
    }
  }

  .suggestions {
    color: $node-suggestion-font-color;
    margin-bottom: 2px;
    margin-top: 5px;

    .entry {
      background-color: $node-suggestion-background-color;
      position: relative;

      .expandIcon {
      }

      .dragIcon,
      .editIcon {
        filter: brightness(2);

        &:hover {
          background: rgba(167, 182, 194, 0.1);
        }

        &:active {
          background: rgba(115, 134, 148, 0.1);
        }
      }

      &.datePicker {
        text-align: center;

        .icon {
          filter: brightness(3);
        }

        input {
          text-align: center;
        }
      }
    }
  }

  .label {
    color: $node-label-font-color;
    margin-bottom: 2px;
    margin-top: 5px;

    .entry {
      position: relative;
      border: 1px solid var(--border-color-base);

      .editor {
        input {
          padding: 2px;
          background-color: $node-label-background-color;
          width: 80%;
        }
      }

      .editIcon {
        filter: brightness(1);
        opacity: 100;
        position: center;
        margin-bottom: 2px;
        padding-left: 5px;
        right: 12px;

        &:hover {
          background: rgba(167, 182, 194, 0.5);
        }

        &:active {
          background: rgba(115, 134, 148, 0.5);
        }
      }

      &.datePicker {
        text-align: center;

        .icon {
          filter: brightness(3);
        }

        input {
          text-align: center;
        }
      }
    }
  }

  .outcomes {
  }
}

.header {
  position: relative;
  text-align: left;
  padding: 10px;
  display: flex;
  align-items: center;
  border-radius: 10px 10px 0px 0px;
  font-weight: 500;
  letter-spacing: 0.25px;

  &:hover .controlGroup {
    opacity: 1;
    pointer-events: auto;
    transform: translateX(-10px);
  }

  .icon {
    height: 24px;
    width: 24px;
    display: inline-block;
  }

  & .moreIcon {
    margin-left: auto;
    height: 24px;
    cursor: pointer;

    &:hover {
      background: rgba(167, 182, 194, 0.3);
    }

    &:active {
      background: rgba(115, 134, 148, 0.3);
    }
  }

  span {
  }

  > input {
    padding: 0 4px;
    margin-left: 8px;
    width: 100%;
    border: none;
    border-radius: 4px;

    &:focus-within {
      border: none;
      outline: 2px solid #6a6a6a;
    }
  }

  & .nodeLabel {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 4px;
    margin-left: 8px;

    > svg {
      cursor: pointer;
    }
  }
}

.node {
  cursor: move;
  //border: 2px solid $node-border-color;
  border: $node-border;
  box-shadow: $node-box-shadow;
  border-radius: 12px;
  overflow: visible;
  font-size: 0.75rem;
  color: $node-content-font-color;
  background: $node-background-color;
  //background: #3474ac !important;
  //color: #fff !important;
  width: 210px;
  max-height: 100%;
  word-break: break-word;
  //box-shadow: 0px 0px 10px 6px rgba(0, 0, 0, 0.1);
  transition: border 200ms ease-in-out;

  &:hover {
    border: 2px solid var(--node-color);
    //box-shadow: 0 2px 6px 0 #3474ac9f;
  }

  .handle {
    // because in production build it doesn't opverride default styles otherwise
    // maybe switch to not using default react-flow styling
    border: none;
    //background: orange ;
    background-color: $node-handle-color;
    right: -14px;
    width: 8px;
    height: 8px;

    z-index: 999;

    &:hover {
      transform: translateY(-49%) rotate(45deg) scale(2) !important;
      border: 1px inset #ffcc82 !important;
    }

    &.conditionTrue {
      height: 12px;
      width: 12px;
      right: -16px;
      background-color: #d0ffed;
      border: 2px solid #3ec08d;
    }

    &.conditionFalse {
      height: 12px;
      width: 12px;
      right: -16px;
      background-color: #ffdcd7;
      border: 2px solid #d9776a;
    }
  }
}

.actionNode {
  cursor: move;
  //border: 2px solid $node-border-color;
  border: $node-border;
  box-shadow: $node-box-shadow;
  border-radius: 12px;
  overflow: visible;
  font-size: 0.75rem;
  color: $node-content-font-color;
  background: $node-background-color;
  //background: #3474ac !important;
  //color: #fff !important;
  min-width: 200px;
  max-width: 500px;
  max-height: 100%;
  word-break: break-word;
  //box-shadow: 0px 0px 10px 6px rgba(0, 0, 0, 0.1);
  transition: border 200ms ease-in-out;

  &:hover {
    border: 2px solid var(--node-color);
    //box-shadow: 0 2px 6px 0 #3474ac9f;
  }

  .handle {
    // because in production build it doesn't opverride default styles otherwise
    // maybe switch to not using default react-flow styling
    border: none;
    //background: orange ;
    background-color: $node-handle-color;
    right: -14px;
    width: 8px;
    height: 8px;

    z-index: 999;

    &:hover {
      transform: translateY(-49%) rotate(45deg) scale(2) !important;
      border: 1px inset #ffcc82 !important;
    }

    &.conditionTrue {
      background: darkgreen !important;
      border-radius: 10px !important;
      height: 10px;
      width: 10px;
    }

    &.conditionFalse {
      background: darkred !important;
      border-radius: 10px !important;
      height: 10px;
      width: 10px;
    }
  }
}

.faqNode {
  cursor: move;
  //border: 2px solid $node-border-color;
  border: $node-border;
  box-shadow: $node-box-shadow;
  border-radius: 12px;
  overflow: visible;
  font-size: 0.75rem;
  color: $node-content-font-color;
  background: $node-background-color;
  //background: #3474ac !important;
  //color: #fff !important;
  min-width: 300px;
  max-width: 300px;
  max-height: 100%;
  word-break: break-word;
  //box-shadow: 0px 0px 10px 6px rgba(0, 0, 0, 0.1);
  transition: border 200ms ease-in-out;

  &:hover {
    border: 2px solid var(--node-color);
    //box-shadow: 0 2px 6px 0 #3474ac9f;
  }

  .handle {
    // because in production build it doesn't opverride default styles otherwise
    // maybe switch to not using default react-flow styling
    border: none;
    //background: orange ;
    background-color: $node-handle-color;
    right: -14px;
    width: 8px;
    height: 8px;

    z-index: 999;

    &:hover {
      transform: translateY(-49%) rotate(45deg) scale(2) !important;
      border: 1px inset #ffcc82 !important;
    }

    &.conditionTrue {
      background: darkgreen !important;
      border-radius: 10px !important;
      height: 10px;
      width: 10px;
    }

    &.conditionFalse {
      background: darkred !important;
      border-radius: 10px !important;
      height: 10px;
      width: 10px;
    }
  }
}

.setSlotNode {
  cursor: move;
  //border: 2px solid $node-border-color;
  border: $node-border;
  box-shadow: $node-box-shadow;
  border-radius: 12px;
  overflow: visible;
  font-size: 0.75rem;
  color: $node-content-font-color;
  background: $node-background-color;
  //background: #3474ac !important;
  //color: #fff !important;
  min-width: 250px;
  max-width: 250px;
  max-height: 100%;
  word-break: break-word;
  //box-shadow: 0px 0px 10px 6px rgba(0, 0, 0, 0.1);
  transition: border 200ms ease-in-out;

  &:hover {
    border: 2px solid var(--node-color);
    //box-shadow: 0 2px 6px 0 #3474ac9f;
  }

  .handle {
    // because in production build it doesn't opverride default styles otherwise
    // maybe switch to not using default react-flow styling
    border: none;
    //background: orange ;
    background-color: $node-handle-color;
    right: -14px;
    width: 8px;
    height: 8px;

    z-index: 999;

    &:hover {
      transform: translateY(-49%) rotate(45deg) scale(2) !important;
      border: 1px inset #ffcc82 !important;
    }

    &.conditionTrue {
      background: darkgreen !important;
      border-radius: 10px !important;
      height: 10px;
      width: 10px;
    }

    &.conditionFalse {
      background: darkred !important;
      border-radius: 10px !important;
      height: 10px;
      width: 10px;
    }
  }
}

.conditionButton {
  height: 60px;
  border: 1px dashed $condition-node-button-border-color;
  background: $condition-node-button-background-color;
  border-radius: 5px;
  display: flex;
  transition: border 200ms ease-in-out;

  &:hover {
    background: $condition-node-button-hover-background-color;
    border: 1px dashed $condition-node-button-hover-border-color;
  }

  span {
    margin: auto;
  }
}

.controlGroup {
  display: flex;
  opacity: 0;
  padding: 5px 10px 5px 5px;
  pointer-events: none;
  position: absolute;
  right: calc(100% - 10px);
  top: -5px;
  transition: opacity 0.2s, transform 0.2s;
  z-index: 10;

  &:not(:last-child) button {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    margin-right: -1px;
  }

  button {
    align-items: center;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    display: inline-flex;
    flex-direction: row;
    font-size: 14px;
    justify-content: center;
    min-height: 30px;
    min-width: 30px;
    padding: 5px 5px;
    text-align: left;
    vertical-align: middle;
    background: $node-hover-menu-background-color;
    box-shadow: none;

    &:active {
      background-color: $node-hover-menu-active-background-color !important;
    }

    &:focus {
      outline: none;
    }

    &:hover {
      background-clip: padding-box;
      background: $node-hover-menu-hover-background-color;
    }

    &:first-child {
      border-radius: 30px;
      padding-left: 12px;
    }

    &:last-child {
      border-radius: 30px;
      padding-right: 12px;
    }

    &:not(:last-child) {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
      margin-right: -1px;
    }

    &:not(:first-child) {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }

    .icon {
      height: 16px;
      cursor: pointer;
      display: inline-flex;
      flex-direction: row;
    }
  }
}
